export const CREATING_SESSION = 'CREATING_SESSION'
export const QUICK_WALLET_BACKUP = 'QUICK_WALLET_BACKUP'
export const GETTING_OWNER_IDENTITIES = 'GETTING_OWNER_IDENTITIES'
export const UPLOADING_ACCOUNT_DATA = 'UPLOADING_ACCOUNT_DATA'
export const OPENING_CAMPAIGN = 'OPENING_CAMPAIGN'
export const GETTING_CAMPAIGNS_FEES = 'GETTING_CAMPAIGNS_FEES'
export const UPDATING_ACCOUNT_IDENTITY = 'UPDATING_ACCOUNT_IDENTITY'
export const UPDATING_SLOTS_DEMAND = 'UPDATING_SLOTS_DEMAND'
export const PRINTING_CAMPAIGNS_RECEIPTS = 'PRINTING_CAMPAIGNS_RECEIPTS'
export const FETCHING_PUBLISHER_RECEIPTS = 'FETCHING_PUBLISHER_RECEIPTS'
export const CHECKING_METAMASK_AUTH = 'CHECKING_METAMASK_AUTH'
export const AUTH_WAITING_TREZOR_ACTION = 'AUTH_WAITING_TREZOR_ACTION'
export const AUTH_WAITING_ADDRESS_DATA = 'AUTH_WAITING_ADDRESS_DATA'
export const UPDATING_TARGETING_DATA = 'UPDATING_TARGETING_DATA'
export const SYNC_WEB3_DATA = 'UPDATING_WEB3_DATA'
