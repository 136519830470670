import React from 'react'
import createSvgIconCustom from './createSvgIconCustom'

export default createSvgIconCustom(
	<g>
		<path d='M7.23,5.37,1.4,5.3A1.34,1.34,0,0,0,0,6.63L.07,10.3a1.42,1.42,0,0,0,1.08,1.32,1.52,1.52,0,0,0,.37,0H2.2L.88,16.39a.72.72,0,0,0,.47.85h0a1.93,1.93,0,0,0,1.45-.05,1.7,1.7,0,0,0,.93-1L5.2,11.71l2.15,0Z' />
		<path d='M24.33,5.95,24.38,0a.05.05,0,0,0,0,0h0L9.16,4.9l.08,7.33,14.65,4.46a.28.28,0,0,0,.24,0,.25.25,0,0,0,.11-.2l0-4.16h0V10.94a2.14,2.14,0,0,0,1.82-2.4A2.24,2.24,0,0,0,24.33,5.95Z' />
	</g>,

	'Campaign',
	'0 0 26.12 17.37'
)
